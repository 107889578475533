@import "scss/fonts";
@import "scss/theme";

a {
  cursor: pointer;
}

html,
body {
  height: 100%;
  margin: 0;
  background-color: #202020;
  color: white;
}

app {
  display: flex;
  flex-direction: column;
  height: 100%;
}

mat-form-field {
  width: 100%;
}

mat-button-toggle {
  display: flex;
  align-items: center;
  height: 3.5rem;
}
//
//body {
//  margin: 0;
//  font-family: Roboto, "Helvetica Neue", sans-serif;
//}
//
.full-width {
  width: 100%;
}

//
.spacer {
  flex: 1 1 auto;
}

.hr {
  border-bottom: 1px solid #a0a0a0;
  opacity: 0.2;
}

//
.fullpage {
  height: calc(100vh - 64px) !important;
}

.fullpagefooter {
  height: calc(100vh - 64px - 66px) !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.i {
  font-style: italic !important;
}

a {
  text-decoration: none;
  color: white;
}

//
//
//mat-sidenav-container {
//  min-height: 100%;
//}
//
//.mat-drawer-inner-container {
//
//}
//
//mat-sidenav {
//  width: 200px;
//  //padding-bottom: 64px;
//  //max-height: calc(100vh - 64px);
//
//}
//
////mat-nav-list {
////  display: flex!important;
////  flex-direction: column;
////  height: 100%;
////
////}
//
//.mat-expansion-panel-content > .mat-expansion-panel-body {
//  padding: 0 !important;
//}
//
//mat-card {
//  display: flex!important;
//  flex-direction: column;
//}
//
//mat-card-header {
//  flex-shrink: 0;
//}
//
//mat-card-content {
//  flex-grow: 1;
//  overflow: auto;
//}

//input:-webkit-autofill {
//  -webkit-box-shadow: 0 0 0 1000px #1f1f1f inset;
//}

input:-webkit-autofill {
  -webkit-background-clip: text;
}

input:-webkit-autofill {
  -webkit-text-fill-color: white !important;
}

.carousel button {
  transform: translateY(0) !important;
  bottom: 16px !important;
  top: unset !important;
}

.no-background {
  background: transparent !important;
}

.mat-raised-button,
.mat-button {
  border-radius: 30px !important;
  padding: 0 30px !important;
}

.form-row {
  display: flex;
  gap: 24px;
}

.actions-row {
  display: flex;
  justify-content: end;
}

.mat-radio-label-content,
.mat-checkbox-label {
  font-size: 12px !important;
  line-height: 18px !important;
}

.mat-menu-panel {
  background: #161616 !important;
  border-radius: 16px !important;
}

.pointer {
  cursor: pointer;
}

/* .mat-dialog-container {
  border-radius: 20px !important;
} */
